<template>
  <div class="Contacts Wrapper">
    <div class="Contacts-Wrapper">
      <div class="Contacts-Left">
        <h1 class="Contacts-Title Title Title--h2 Title--h2-small">{{ $t('contacts.title') }}</h1>
        <h2 class="Contacts-BotTitle Title Title--h4" v-html="$t('contacts.botTitle')"></h2>
        <p class="Contacts-BotText Text" v-html="$t('contacts.botText')"></p>
        <p class="Contacts-BotBtn">
          <a class="Button" href="https://t.me/SyndicateG_bot" target="_blank">
            <span class="Button-Text">{{ $t('contacts.botBtnText') }}</span>
            <span class="Button-Shadow"></span>
          </a>
        </p>
      </div>
      <div class="Contacts-Right">
        <p class="Contacts-PreFormText Text" v-html="$t('contacts.formText')"></p>

        <div class="Contacts-Form">
          <h2 class="Contacts-FormTitle Title Title--h4" v-html="$t('contacts.formTitle')"></h2>
          <Form :isRows="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/Form'

export default {
  components: {
    Form
  }
}
</script>

<style lang="scss">
%line {
  @media (min-width: $screen-l) {
    position: relative;  

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 300px;
      background: radial-gradient(circle, var(--color-text-main2) 0%, var(--color-body) 100%);
      opacity: 0.2;
    }
  }
}

.Contacts {
  margin-bottom: 60px;

  &-Wrapper {
    @media (min-width: $screen-l) {
      display: flex;
    }
  }

  &-Left {
    @media (min-width: $screen-l) {
      width: 36%;
      flex: 0 0 auto;
    }
  }

  &-Right {
    @media (min-width: $screen-l) {
      width: 64%;
      max-width: 606px;
      margin-left: auto;
      padding-left: 40px;
      flex: 0 0 auto;
    }
  }

  &-Title {
    @extend %line;
    padding-bottom: 60px;
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: left;
      padding-top: 85px;
      padding-bottom: 85px;
    }
  }

  &-BotTitle {
    margin-bottom: 16px;
    text-align: center;

    @media (min-width: $screen-l) {
      margin-bottom: 24px;
      text-align: left;
    }
  }

  &-BotText {
    margin-bottom: 28px;
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: left;
      margin-bottom: 65px;
    }
  }

  &-BotBtn {
    @extend %line;

    .Button {
      width: 100%;

      @media (min-width: $screen-l) {
        width: 312px;
      }
    }
  }

  &-PreFormText {
    position: relative;
    padding-top: 60px;
    padding-bottom: 24px;

    @media (min-width: $screen-l) {
      padding-top: 85px;
      padding-bottom: 85px;
      padding-right: 130px;
    }

    &::before {
      content: '';
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 26px;
      width: 84px;
      height: 78px;
      background-image: url('~@/assets/images/envelope.svg');
      background-size: 100% auto;
      
      @media (min-width: $screen-l) {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        margin: 0;
        width: 106px;
        height: 98px;
      }
    }
  }

  &-Form {

  }

  &-FormTitle {
    margin-bottom: 20px;

    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }
}
</style>
